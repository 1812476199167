import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <h1>About</h1>
      <h2>Attendances database stats:</h2>
      <p>Competitions: {data.mysqlCompetitionscount.competitionscount}</p>
      <p>Teams: {data.mysqlTeamscount.teamscount}</p>
      <p>Games: {data.mysqlGamescount.gamescount}</p>
      <h2>Contact</h2>
      <p>
        <span style={{ float: "left" }}>Email: </span>{" "}
        <Img
          fixed={data.email.childImageSharp.fixed}
          alt="logo"
          style={{ margin: "margin: 3px 0 0 5px" }}
        />
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    mysqlGamescount {
      gamescount
    }
    mysqlTeamscount {
      teamscount
    }
    mysqlCompetitionscount {
      competitionscount
    }
    email: file(relativePath: { eq: "email.png" }) {
      childImageSharp {
        fixed(height: 17) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
